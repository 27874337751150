import { render, staticRenderFns } from "./FormFinal.vue?vue&type=template&id=1cd2902e&scoped=true&"
import script from "./FormFinal.vue?vue&type=script&lang=js&"
export * from "./FormFinal.vue?vue&type=script&lang=js&"
import style1 from "./FormFinal.vue?vue&type=style&index=1&id=1cd2902e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1cd2902e",
  null
  
)

export default component.exports
import QField from 'quasar/src/components/field/QField.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QField,QCard,QCardSection,QBtn,QDialog,QCardActions});qInstall(component, 'directives', {ClosePopup});
